import React from 'react';
import { styled } from '@material-ui/core/styles';
import { faClock, faUser, faAngleRight} from "@fortawesome/free-solid-svg-icons"
import { Grid, makeStyles} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from "gatsby-theme-material-ui";

const ReadMore = styled(Button)({
    background: 'linear-gradient(45deg, #ff009a 30%, #ff009a 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 31,
    padding: '0 30px',
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlighn: 'center',
        color: theme.palette.text.secondary,
        height: 100,
    },
    supurp: {
        marginBottom: 5,
    }
}))

export default function ArticleListing (props) {
    const classes = useStyles();
  return (
    <div>
                        <h5>{props.title}</h5>
                        <Grid container>
                            <Grid  item xs={12}>
                                <time className="pr-10">
                                    <FontAwesomeIcon className="mr-8" style={{width: '1.125em'}} aria-label="Mute volume" icon={faClock} />
                                    <span>{props.date}</span>
                                </time>
                                <time>
                                    <FontAwesomeIcon className="mr-8" style={{width: '1.125em'}} aria-label="Mute volume" icon={faUser} />
                                    <span>{props.writtenby}</span>
                                </time>
                            </Grid>
                            <p className={classes.supurp}>
                                {props.supurb}
                            </p>
                        </Grid>
                        <ReadMore variant="outlined" to={props.link}>Read More<FontAwesomeIcon className="mr-8"  style={{width: '1.125em'}}  aria-label="Mute volume" icon={faAngleRight} /></ReadMore>
                        <br />
            
    </div>
  );
}