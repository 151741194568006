import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Grid from "@material-ui/core/Grid";
import Mission from "../components/Mission";
import SliderBootstrapStatic from "../components/SliderBootstrapStatic";
import { makeStyles } from "@material-ui/core";
import ArticleListing from "../components/ArticleListing";

const PageDetails = {
  title: "Articles",
  author: "Savante Consulting Limited",
  description: "",
};

const useStyles = makeStyles({
  root: {
    "& li": {
      listStyleType: "disc",
    },
    "& p": {
      textAlign: "justify",
    },
  },
});
export default function AboutUs() {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title={PageDetails.title} description={PageDetails.description} />
      <br />
      <br />

      <SliderBootstrapStatic title={PageDetails.title} />
      <div className="container">
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} lg={8}>
            <div className={classes.root}>
              <br />

              <ArticleListing
                image=""
                title="Clinical Trials & Contract Research In Nigeria"
                date="June 22th, 2021"
                writtenby={PageDetails.author}
                supurb="Clinical trials are critical for identifying novel therapies for diseases and new techniques to detect, diagnose, and prevent disease development. In addition, clinical trials may demonstrate to researchers what works and doesn't work in people, which isn't possible to discover in the lab or with animals."
                link="/Articles/Clinical-Trials-and-Contract-Research-In-Nigeria"
              />
              <br />
              
              <ArticleListing
                image=""
                title="Clinical Research Services in Nigeria"
                date="June 8th, 2021"
                writtenby={PageDetails.author}
                supurb="We've put together a comprehensive package of clinical research services to help you get the tools and expertise you need for your clinical research.  These services are designed to meet the highest standards throughout your drug development strategy.  Savante Consulting Limited is a Nigerian ..."
                link="/Articles/Clinical-Research-Services-in-Nigeria"
              />
              <br />

              
               
                        <ArticleListing
                                image =""
                                title ="Clinical Research Organizations in Nigeria"
                                date="March 28th, 2021"
                                writtenby={PageDetails.author}
                                supurb="As one of the leading clinical research organizations in Nigeria, Savante Consulting Limited provides the resources and expertise you need for your clinical research, we have the right package of clinical research services to deliver to the highest standards throughout your drug development plan."
                                link="/Articles/Clinical-Research-Organizations-in-Nigeria"
                        />
                        <br />
                        
              <ArticleListing
                image=""
                title="Partnerships and Collaborations: The Right Alliances for Clinical Trials in Africa"
                date="March 14th, 2021"
                writtenby="Olusola Solarin, MBA; Sulma I. Mohammed, DVM, PhD; Ntokozo Ndlovu, MMed; Verna Vanderpuye, MBChB; and Victoria Olaiya, BSc, BDS"
                supurb="
                                  Africa attracts,1% of all trials conducted around the world. The implication is that proof of safety and efﬁcacy 
                                  in Africans is lacking for a lot of new therapies. "
                link="/Articles/Partnerships-and-Collaborations-The-Right-Alliances-for-Clinical-Trials-in-Africa"
              />
              <br />
              <ArticleListing
                image=""
                title="Product Registration in CEMAC, ECOWAS and UEMOA Regions"
                date="March 13th, 2021"
                writtenby={PageDetails.author}
                supurb="
                                Products such as foods, biologicals, pharmaceuticals and other medical devices are important for national developments. 
                                Every country needs a reliable supply of all these products to promote safety, improve public health and patient care.
                                "
                link="/Articles/Product-Registration-in-CEMAC-ECOWAS-and-UEMOA-Regions"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <br />
            <Mission />
          </Grid>
        </Grid>
        <br />
        <br />
      </div>
    </Layout>
  );
}
